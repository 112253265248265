import React from 'react';

const Hero = () => {
  return (
    <div className="relative bg-white">
      <div 
        className="absolute inset-0 bg-cover bg-center z-0"
        style={{
          backgroundImage: `url('https://cdn.speedsize.com/eb8d0010-7300-4129-8a6d-74bc221f9caf/https://www.virginvoyages.com/dam/jcr:e38c13f7-b218-4102-9ce1-a35d4d243ba9/FM_CMI_VVcom_1600x700.jpg')`,
        }}
      ></div>
      <div className="relative z-10 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-16 sm:py-24 lg:py-32">
        <div className="flex flex-col lg:flex-row items-center">
          <div className="lg:w-1/2 lg:pr-8 bg-white bg-opacity-90 p-8 rounded-lg">
            <div className="flex items-center mb-4">
              <div className="bg-blue-500 rounded-full p-1 mr-2">
                <svg className="w-5 h-5 text-white" fill="currentColor" viewBox="0 0 20 20">
                  <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
                </svg>
              </div>
              <p className="text-blue-500 font-semibold text-sm">Transform Your Corporate Events</p>
            </div>
            <h1 className="text-4xl sm:text-5xl lg:text-6xl font-bold text-gray-900 leading-tight mb-4">
              Host Unforgettable <br />
              Corporate Events <br />
              at Sea with Virgin Voyages
            </h1>
            <p className="text-lg text-gray-600 mb-8">
              Discover a unique venue for your next corporate gathering. With breathtaking ocean views and exceptional service, 
              <span className="font-bold text-blue-500"> your team will experience the extraordinary. </span>
            </p>
            <a
              href="#get-started"
              className="inline-block w-full sm:w-auto px-6 py-3 bg-blue-500 text-white font-bold rounded-lg text-center hover:bg-blue-600 transition duration-300"
            >
              Book Your Event Today
            </a>
            <div className="mt-6 flex items-center">
              <div className="flex -space-x-2 overflow-hidden">
                {[1, 2, 3].map((index) => (
                  <img
                    key={index}
                    className="inline-block h-8 w-8 rounded-full ring-2 ring-white"
                    src={`https://randomuser.me/api/portraits/men/${index}.jpg`}
                    alt=""
                  />
                ))}
              </div>
              <span className="ml-3 text-sm font-semibold text-gray-700">Join other successful companies at sea</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
