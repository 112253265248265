import React, { useState } from 'react';

const FeatureCard = ({ title, description, onHover }) => {
  return (
    <div 
      className="bg-white p-6 rounded-lg shadow-md transition-all duration-300 ease-in-out"
      onMouseEnter={() => onHover(title)}
    >
      <h4 className="text-xl font-bold text-indigo-900 mb-2">{title}</h4>
      <p className="text-gray-600">{description}</p>
    </div>
  );
};

const WhyChooseVirginVoyages = () => {
  const [activeFeature, setActiveFeature] = useState('Unique Venues');

  const features = [
    {
      title: "Unique Venues",
      description: "Choose from a variety of stunning venues aboard the ship, from sophisticated conference rooms to beautiful outdoor decks, perfect for any type of event.",
      image: "/unique-venues.jpg"
    },
    {
      title: "Team Building Activities",
      description: "Engage your team with a range of exciting activities designed to foster collaboration and enhance relationships, all while enjoying the scenic ocean views.",
      image: "/team-building.jpg"
    },
    {
      title: "Culinary Delights",
      description: "Indulge in exquisite dining experiences with a diverse range of cuisines prepared by world-class chefs, ensuring every meal is a memorable part of your event.",
      image: "/culinary-delights.jpg"
    },
    {
      title: "Exclusive Amenities",
      description: "Benefit from exclusive access to luxury amenities, including spas, fitness centers, and entertainment options that make your corporate event truly special.",
      image: "/exclusive-amenities.jpg"
    }
  ];

  return (
    <section className="bg-gray-100 py-16">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-12">
          <h2 className="text-3xl font-extrabold text-gray-900 sm:text-4xl">
            Why Host Your Corporate Event with <span className="text-indigo-600">Virgin Voyages</span>?
          </h2>
          <p className="mt-4 text-xl text-gray-600">
            Experience a transformative approach to corporate gatherings, where business meets pleasure on the high seas.
          </p>
        </div>
        <div className="grid md:grid-cols-2 gap-8">
          <div className="space-y-8">
            <div>
              <h3 className="text-2xl font-bold text-gray-900 mb-4">
                Unparalleled Experience at Sea
              </h3>
              <p className="text-gray-600 mb-6">
                Hosting your corporate event on a Virgin Voyages ship means offering your team an unforgettable experience with luxury amenities, stunning views, and exceptional service.
              </p>
            </div>
            <div className="relative pb-[100%] md:pb-0 h-[400px]">
              <img
                src={features.find(f => f.title === activeFeature).image}
                alt={activeFeature}
                className="rounded-lg shadow-xl w-full h-full object-cover transition-opacity duration-300 ease-in-out"
              />
            </div>
          </div>

          <div className="space-y-8 mt-16 md:mt-0">
            <div>
              <h3 className="text-2xl font-bold text-gray-900 mb-4">
                Tailored Corporate Solutions
              </h3>
              <p className="text-gray-600 mb-6">
                Our dedicated team will work with you to customize your event, ensuring it meets your specific goals and provides an enriching experience for your attendees.
              </p>
            </div>
            <div className="grid grid-cols-1 gap-6">
              {features.map((feature) => (
                <FeatureCard
                  key={feature.title}
                  title={feature.title}
                  description={feature.description}
                  onHover={setActiveFeature}
                />
              ))}
            </div>
          </div>
        </div>

        <div className="mt-16 flex justify-center space-x-2">
          {[...Array(6)].map((_, index) => (
            <div
              key={index}
              className="w-2 h-2 rounded-full bg-indigo-500"
            ></div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default WhyChooseVirginVoyages;
