import React from 'react';
import Header from './Header';
import Hero from './Hero';
import PopularDestinations from './PopularDestinations';
import CallToAction from './CallToAction';
import Footer from './Footer';
import WhyChooseVirginVoyages from './WhyChooseVirginVoyages';
import BenefitsSection from './BenefitsSection';
import Steps from './Steps';
import GetStarted from './getStarted';
import AlwaysIncluded from './AlwaysIncluded';

function LandingPage() {
  return (
    <div className="landing-page">
      <Header />
      <main>
        <Hero />
        <WhyChooseVirginVoyages />
        <BenefitsSection />
        <AlwaysIncluded />
        <Steps />
        {/* <CallToAction /> */}
        <div id="get-started">
          <GetStarted />
        </div>
      </main>
      <Footer />
    </div>
  );
}

export default LandingPage;