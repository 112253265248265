import React from 'react';

const AlwaysIncluded = () => {
  return (
    <section className="bg-white py-16 px-4 sm:px-6 lg:px-8" id="inclusions">
      <div className="max-w-7xl mx-auto">
        <h2 className="text-4xl sm:text-5xl lg:text-6xl font-bold text-black mb-4">
          All-Inclusive Corporate Luxury
        </h2>

        <p className="text-lg sm:text-xl lg:text-2xl text-black mb-12">
          Over $1000 in value per attendee. Elevate your corporate event with our all-inclusive package — no hidden costs, just pure value. Simplify your budgeting and maximize your team's experience.
        </p>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {features.map((feature, index) => (
            <div key={index} className="bg-white">
              <img
                className="w-24 h-24 mx-auto mb-4"
                src={feature.image}
                alt={feature.title}
                width="90"
                height="90"
              />

              <h3 className="text-xl font-semibold text-black mb-2">{feature.title}</h3>
              <p className="text-gray-600">{feature.description}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

// Updated feature data for corporate groups
const features = [
  {
    title: 'High-Speed WiFi',
    description: 'Stay connected with seamless, ship-wide WiFi. Perfect for presentations, virtual meetings, or keeping in touch with the office.',
    image: 'https://cdn.speedsize.com/eb8d0010-7300-4129-8a6d-74bc221f9caf/https://www.virginvoyages.com/dam/jcr:c573aade-bd3c-4cb5-9b80-e0d62b03cd53/Wifi.png'
  },
  {
    title: 'Hassle-Free Gratuities',
    description: 'We handle all tips and gratuities, allowing your team to focus on networking and team-building without worrying about additional costs.',
    image: 'https://cdn.speedsize.com/eb8d0010-7300-4129-8a6d-74bc221f9caf/https://www.virginvoyages.com/dam/jcr:961a8bad-1a12-4074-aa09-bdce34c2fc7b/Tips.png'
  },
  {
    title: 'Gourmet Dining Options',
    description: 'Impress your clients and reward your team with 20+ world-class eateries, perfect for both casual team lunches and formal business dinners.',
    image: 'https://cdn.speedsize.com/eb8d0010-7300-4129-8a6d-74bc221f9caf/https://www.virginvoyages.com/dam/jcr:77794913-94d7-4424-aab8-a2bfabbd63f5/FOOD.png'
  },
  {
    title: 'Complimentary Beverages',
    description: 'Keep your team refreshed with a variety of included non-alcoholic beverages, perfect for long meetings or casual networking sessions.',
    image: 'https://cdn.speedsize.com/eb8d0010-7300-4129-8a6d-74bc221f9caf/https://www.virginvoyages.com/dam/jcr:250e1abf-792f-41e1-8e6f-272449c0a05f/Drinks.png'
  },
  {
    title: 'Team-Building Fitness Classes',
    description: 'Boost team morale and promote wellness with a wide range of group fitness classes, ideal for corporate team-building activities.',
    image: 'https://cdn.speedsize.com/eb8d0010-7300-4129-8a6d-74bc221f9caf/https://www.virginvoyages.com/dam/jcr:fad3db01-0571-48a5-b147-8e7d4098e407/value%20prop-workout.png'
  },
  {
    title: 'World-Class Entertainment',
    description: "Treat your team to unforgettable entertainment experiences, perfect for unwinding after a productive day of meetings and workshops.",
    image: 'https://cdn.speedsize.com/eb8d0010-7300-4129-8a6d-74bc221f9caf/https://www.virginvoyages.com/dam/jcr:b5e8721c-7854-47c0-8333-223b0f2eaf4d/Entertainment%20.png'
  },
];

export default AlwaysIncluded;
