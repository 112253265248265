import React from 'react';

const BenefitItem = ({ icon, title }) => (
  <div className="flex items-center space-x-4">
    <div className="flex-shrink-0">
      {icon}
    </div>
    <p className="text-lg font-bold text-gray-700">{title}</p>
  </div>
);

const BenefitsSection = () => {
  return (
    <section className="bg-gray-50 py-16">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="lg:grid lg:grid-cols-2 lg:gap-8 items-center">
          <div className="mb-10 lg:mb-0">
            <h2 className="text-3xl font-extrabold text-gray-900 sm:text-4xl mb-4">
              Unlock the Benefits of Hosting Your Corporate Event with<br />Virgin Voyages
            </h2>
            <p className="text-xl text-gray-600 mb-6">
              Elevate your corporate gatherings with unique experiences, exceptional service, and stunning settings at sea. Discover how Virgin Voyages can transform your next event.
            </p>
            <a
              href="#get-started"
              className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700"
            >
              Plan Your Event Today
            </a>
            <div className="mt-8 space-y-4">
              <BenefitItem
                icon={<svg className="w-6 h-6 text-indigo-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" /></svg>}
                title="Breathtaking Venues"
              />
              <BenefitItem
                icon={<svg className="w-6 h-6 text-indigo-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" /></svg>}
                title="Exceptional Onboard Service"
              />
              <BenefitItem
                icon={<svg className="w-6 h-6 text-indigo-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" /></svg>}
                title="Unique Team-Building Experiences"
              />
              <BenefitItem
                icon={<svg className="w-6 h-6 text-indigo-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" /></svg>}
                title="Flexible Itineraries"
              />
            </div>
          </div>
          <div className="relative">
            <img
              className="rounded-lg shadow-xl"
              src="/LuxuryCruise.jpeg" // Update this image source to a relevant image of a Virgin Voyages ship or a corporate event at sea
              alt="Luxury cruise ship sailing on the ocean"
            />
            <div className="absolute top-0 left-0 -mt-4 -ml-4 w-24 h-24 bg-indigo-100 rounded-full flex items-center justify-center">
              <svg className="w-12 h-12 text-indigo-600" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
              </svg>
            </div>
            <div className="absolute bottom-0 right-0 mb-4 mr-4 bg-white rounded-lg shadow-md p-4">
              <div className="flex items-center">
                <svg className="w-8 h-8 text-yellow-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                  <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                </svg>
                <div className="ml-3">
                  <p className="text-sm font-semibold text-gray-900">Your corporate event awaits!</p>
                  <p className="text-xs text-gray-600">Luxury cruise experience booked</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BenefitsSection;
